import { render, staticRenderFns } from "./course.vue?vue&type=template&id=44db9d75&scoped=true&"
import script from "./course.vue?vue&type=script&lang=js&"
export * from "./course.vue?vue&type=script&lang=js&"
import style0 from "./course.vue?vue&type=style&index=0&id=44db9d75&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44db9d75",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBottomNavigation,VBtn,VContainer,VIcon})
