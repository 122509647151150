<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-bottom-navigation
        :value="value"
        color="#00BCD4"
      >
      <v-btn
          @click="selectedCompnent = 'DeleteStudentFromDriver'"
        >
          <span>حذف طلاب الدورة</span>

          <v-icon>fa-user-minus</v-icon>
        </v-btn>

        <v-btn
          @click="selectedCompnent = 'AddStudentToDriver'"
        >
          <span>اضافة طلاب للدورة</span>

          <v-icon>fa-user-plus</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <component :is="selectedCompnent"></component>
    </v-container>
  </div>
</template>
<script>
import AddStudentToDriver from './coursProfile/addStudentToCourse.vue'
import DeleteStudentFromDriver from './coursProfile/deleteStudentCours.vue'

export default {
  components: {
    AddStudentToDriver,
    DeleteStudentFromDriver,
  },
  data() {
    return {
      value: 0,
      selectedCompnent: 'DeleteStudentFromDriver',
    }
  },
}
</script>

<style scoped>
.v-item-group.v-bottom-navigation .v-btn {
  padding: 30px;
}
</style>
